/**
 *
 * Footer
 *
 */

import React from 'react';
import FooterWrapper from "./footer"

const Footer = () => (
  <FooterWrapper/>
);

Footer.propTypes = {};

export default Footer;
